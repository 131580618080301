@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  min-width: 0;
  min-height: 0;
  flex-shrink: 0;
}
*::selection {
  @apply bg-yellow-500/80 text-gray-800;
}

:root {
  @apply text-[16px] md:text-[14px] overscroll-none;
  color-scheme: light dark;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

html,
body {
  @apply stack flex-auto overscroll-none antialiased min-h-screen font-sans bg-contrast-0;
}
